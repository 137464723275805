<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from '@/services/Monitor';
import Swal from "sweetalert2";
import modalAddAppParamsKey from '@/components/modals/monitor/modalAddAppParamsKey';
import modalEditAppParamsKey from '@/components/modals/monitor/modalEditAppParamsKey';

export default {
  components: { Layout, PageHeader,  modalAddAppParamsKey, modalEditAppParamsKey},
  page: {
    title: "Tenant Application Access",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  created() {

    this.getTenantApplications();
    this.getInputTypes();
  },

  methods: {
    modalAddAppParamsKey(){
      this.$bvModal.show("add_new_app_params")
    },

    getTenantApplications() {
      Monitor.getAppParams()
          .then((response) => {
            if (response && response.data && Array.isArray(response.data.app_params_keys)) {
              this.tableData = response.data.app_params_keys;
            } else {
              this.tableData = [];
            }
            this.totalRows = this.tableData.length;
          })
          .catch((error) => {
            this.tableData = [];
          });
    },
    getInputTypes() {
      Monitor.getAppParams()
        .then((response) => {
          if (response && response.data && Array.isArray(response.data.app_params_keys)) {
            const inputTypes = new Set(response.data.app_params_keys.map(item => item.input_type));
            this.inputTypeOptions = [...inputTypes];
          } else {
            this.inputTypeOptions = [];
          }
        })
        .catch((error) => {
          this.inputTypeOptions = [];
        });
    },


    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    callmodalEditAppParamsKey(item){
      this.modalData = item
      this.$bvModal.show("edit_app_params")
    },
    deleteAppParamKeys(key){
      Swal.fire({
        title: "Delete Application Params?",
        text: "Remove this Application Params",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
      }).then((result) => {
        if (result.value) {
          Monitor.deleteAppParams(key)
              .then((response) => {
                const res = response.data ? response.data : false;
                const error = response.data.error ? response.data.error : 'Failed';
                if(res){
                  this.getTenantApplications()
                  Swal.fire("Remove Application Params", "Application Params successfully removed!", "success");
                }else{
                  Swal.fire("Fail!", error, "warning");
                }
              })
              .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "Delete Failed";
                this.failedmsg(this.error)
              })
        }
      });
    },
  },


  data() {
    return {
      props: {
        items: {
          type: [Array, Function],
          required: true
        }
      },
      title: "Application Params Keys",
      items: [
        {
          text: "Application",
          href: "/application",
        },
        {
          text: "Application Params",
          active: true,
        },
      ],

      isBusy: false,
      error: null,
      showModal:false,
      modalData:{},
      link_id:null,
      tenants: [],
      currentTenant: 'MON',
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "app_id",
      sortDesc: true,
      fields: [
        {
          key: "key",
          label: "Key",
          sortable: false,
        },
        {
          key: "name",
          label: "Name",
          sortable: false,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "input_type",
          label: "Input Type",
          sortable: true,
        },
        "edit",
        "delete",
      ],
      inputTypeOptions: [], 
    };
  },

  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="modalAddAppParamsKey">
                  <i class="mdi mdi-plus me-2"></i>
                  Add New Application Param Keys
                </a>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
          </div>


          <div class="row">
            <div class="col-sm-12">
              <div class="table-responsive mb-0">
                <b-table
                    :busy="isBusy"
                    :items="tableData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    show-empty
                    empty-text="No Data Found"
                    @filtered="onFiltered"
                >

                  <!-- <template v-slot:cell(key)="data">
                    <span class='badge bg-success font-size-14'>{{data.item.key}}</span>
                  </template> -->

                  <template v-slot:cell(edit)="data">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callmodalEditAppParamsKey(data.item)">
                          <i class="uil uil-pen font-size-18"></i>
                        </a>
                      </li>
                    </ul>
                  </template>

                  <template v-slot:cell(delete)="data">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove" @click="deleteAppParamKeys(data.item.key)">
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </li>
                    </ul>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                </b-table>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!-- MODALS -->
    <modalAddAppParamsKey  @onRefresh="getTenantApplications" :tenant="currentTenant" :inputTypeOptions="inputTypeOptions"></modalAddAppParamsKey>
    <modalEditAppParamsKey @onRefresh="getTenantApplications" :modalData="modalData" :tenant="currentTenant" :inputTypeOptions="inputTypeOptions"></modalEditAppParamsKey>
    <!-- /MODALS -->


  </Layout>
</template>
