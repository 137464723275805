<script>
import Monitor from '@/services/Monitor';
import Swal from "sweetalert2";
import validationMessages from '@/components/validations';
import { required} from "vuelidate/lib/validators";


export default {
  components:{
    validationMessages,
  },
  props: {
    modalData: {
      type: Object,
      default: null
    },
    tenant: {
      type: String,
      default: null
    },
    inputTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showModal: false,
      tryingToSubmit: false,
      submitted: false,
      csrf_token: localStorage.getItem('csrf_token'),
      application: {
        key: '',
        name: '',
        type: '',
        input_type: '',
        old_key: '',
      },
      paramTypes: ['settings', 'terms'], // Hardcoded Params Type
    };
  },
  validations: {
    application: {
      key: { required },
      name: { required },
      type: { required },
      input_type: { required }
    }
  },

  methods: {
    shownModal(){
      this.syncData()
    },
    syncData(){
      //console.log(this.popupsData)
      this.application.old_key = this.modalData.key
      this.application.key = this.modalData.key
      this.application.name = this.modalData.name
      this.application.type = this.modalData.type
      this.application.input_type = this.modalData.input_type
    },


    deleteAppParamKeys(key){
      Swal.fire({
        title: "Delete Application Access?",
        text: "Remove this Application Access",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
      }).then((result) => {
        if (result.value) {
          Monitor.deleteAppParams(key)
              .then((response) => {
                const res = response.data ? response.data : false;
                const error = response.data.error ? response.data.error : 'Failed';
                if(res){
                  this.getTenantApplications()
                  Swal.fire("Remove Application", "Application KEY successfully removed!", "success");
                }else{
                  Swal.fire("Fail!", error, "warning");
                }
              })
              .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "Delete Failed";
                this.failedmsg(this.error)
              })
        }
      });
    },
    updateAppParam() {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to edit the app parameters?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, edit it!',
        cancelButtonText: 'No, cancel!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.tryingToSubmit = true;
          this.submitted = true;

          this.$v.$touch();

if (this.$v.$invalid) {
  this.tryingToSubmit = false;
  return;
}

          Monitor.editAppParams({
            old_key: this.modalData.key,
            key: this.application.key,
            name: this.application.name,
            type: this.application.type,
            input_type: this.application.input_type
          })
              .then((response) => {
                if (response) {
                  Swal.fire('Success', 'App Params Updated!', 'success');
                  this.refreshData();
                  this.closeModal();
                } else {
                  Swal.fire('Failed', 'Edit Failed!', 'error');
                }
              })
              .catch(error => {
                const resData = error.response.data.error ? error.response.data.error : 'Failed';
                const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                Swal.fire('Error', errorMsg, 'error');
              })
              .finally(() => {
                this.tryingToSubmit = false;
              });
        }
      });
    },


    resetProps(){
      this.submitted = false
    },
    closeModal() {
      this.showModal = false
    },
    refreshData() {
      this.$emit('onRefresh') //event from parent
    },
  },
};
</script>

<template>
  <b-modal  @shown="shownModal()" id="edit_app_params" size="md" v-model="showModal" @hidden="resetProps" title="Edit Marketing Popup" title-class="font-18">
    <form @submit.prevent="updateAppParam">
      <div class="row">
        <div class="col-12">

          <div class="mb-3">
            <label class="control-label form-label">Application Key *</label>
          <b-form-input v-model="application.key" type="text"
                            id="formrow-key-input"
                            aria-describedby="key-feedback" :class="{ 'is-invalid': submitted && $v.application.key.$error }">
              </b-form-input>
              <validationMessages v-if="submitted && $v.application.key.$error" :fieldName="'Key'" :validationName="$v.application.key"></validationMessages>
          </div>
          <div class="mb-3">
            <label class="control-label form-label">Application Name *</label>
          <b-form-input v-model="application.name" type="text"
                            id="formrow-name-input"
                            aria-describedby="name-feedback" :class="{ 'is-invalid': submitted && $v.application.name.$error }">
              </b-form-input>
              <validationMessages v-if="submitted && $v.application.name.$error" :fieldName="'Name'" :validationName="$v.application.name"></validationMessages>
          </div>
          <div class="mb-3">
            <label class="control-label form-label">Params Type *</label>
            <b-form-select v-model="application.type" class="form-select">
              <option v-for="type in paramTypes" :key="type" :value="type" :class="{ 'is-invalid': submitted && $v.application.type.$error }">{{ type }}</option>
            </b-form-select>
            <validationMessages v-if="submitted && $v.application.type.$error" :fieldName="'Type'" :validationName="$v.application.type"></validationMessages>
          </div>
          <div class="mb-3">
            <label class="control-label form-label">Params Input Type *</label>
         
          <b-form-select v-model="application.input_type" :options="inputTypeOptions"
                             id="formrow-input-type-input"
                             aria-describedby="input-type-feedback" :class="{ 'is-invalid': submitted && $v.application.input_type.$error }"  class="form-select">
              </b-form-select>
              <validationMessages v-if="submitted && $v.application.input_type.$error" :fieldName="'Input Type'" :validationName="$v.application.input_type"></validationMessages>
          </div>
        </div>
        </div>
    

      <input type="hidden" name="csrf_token" v-model="csrf_token">
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="updateAppParam" :disabled="tryingToSubmit">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Change
      </b-button>
    </template>

  </b-modal>

</template>