<script>
import validationMessages from '@/components/validations';
import { required, minLength, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import Monitor from '@/services/Monitor';


export default {
  components:{
    validationMessages,
  },
  props: {
    tenant: {
      type: String,
      default: null
    },
    inputTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showModal: false,
      tryingToSubmit: false,
      submitted: false,
      application: {
        key: '',
        name: '',
        type: '',
        input_type: '',
      },
      paramTypes: ['settings', 'terms'], // Hardcoded Params Type
      // inputTypes: ['string', 'url', 'boolean', 'img'], // Hardcoded Params Input Types
      csrf_token: localStorage.getItem('csrf_token')
    };
  },
  validations: {
    application: {
      key: { required },
      name: { required },
      type: { required },
      input_type: { required }
    }
  },
  methods: {
    refreshData() {
      this.$emit('onRefresh') //event from parent
    },
    closeModal() {
      this.showModal = false
    },
    resetProps(){
      this.submitted = false
      this.application.key = ''
      this.application.name = ''
      this.application.type = ''
      this.application.input_type = ''

    },

    createAppParamsKey() {
      this.tryingToSubmit = true;
      this.submitted = true;

      this.$v.$touch();

if (this.$v.$invalid) {
  this.tryingToSubmit = false;
  return;
}

      let formData = new FormData();
      formData.append('key', this.application.key);
      formData.append('name', this.application.name);
      formData.append('type', this.application.type);
      formData.append('input_type', this.application.input_type);

      Monitor.createAppParams(formData)
          .then((response) => {
            console.log('API response:', response); 
            const res = response.data.data ? response.data.data : false;
            const error = response.data.error ? response.data.error : '';

            if (!res) {
              this.successmsg('Application Key Created!');
              this.refreshData();
              this.closeModal();
            } else {
              this.failedmsg('Failed to create new Application Key!');
            }
          })
          .catch(error => {
            console.error('API error:', error); 
            const resData = error.response.data.error ? error.response.data.error : 'Failed';
            const errorMsg = Array.isArray(resData) ? resData[0] : resData;
            this.failedmsg(errorMsg);
          })
          .finally(() => {
            this.tryingToSubmit = false;
          });
    }
  }
};
</script>
<template>
  <b-modal id="add_new_app_params" size="md" v-model="showModal" @hidden="resetProps" title="Add New Application Access" title-class="font-18">
    <form @submit.prevent="createAppParamsKey">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <b-form-group label="Key *" label-for="application.key" class="mt-4">
              <b-form-input
                v-model="application.key"
                type="text"
                id="application.key"
                aria-describedby="key-feedback"
                :class="{ 'is-invalid': submitted && $v.application.key.$error }"
              ></b-form-input>
              <validationMessages v-if="submitted && $v.application.key.$error" :fieldName="'Key'" :validationName="$v.application.key"></validationMessages>
            </b-form-group>
          </div>
          <div class="mb-3">
            <b-form-group label="Name *" label-for="application.name" class="mt-4">
              <b-form-input
                v-model="application.name"
                type="text"
                id="application.name"
                aria-describedby="name-feedback"
                :class="{ 'is-invalid': submitted && $v.application.name.$error }"
              ></b-form-input>
              <validationMessages v-if="submitted && $v.application.name.$error" :fieldName="'Name'" :validationName="$v.application.name"></validationMessages>
            </b-form-group>
          </div>
          <div class="mb-3">
            <b-form-group label="Params Type *" label-for="application.type" class="mt-4">
              <b-form-select
                v-model="application.type"
                :options="paramTypes"
                id="application.type"
                aria-describedby="type-feedback"
                :class="{ 'is-invalid': submitted && $v.application.type.$error }"
                class="form-select"
              ></b-form-select>
              <validationMessages v-if="submitted && $v.application.type.$error" :fieldName="'Params Type'" :validationName="$v.application.type"></validationMessages>
            </b-form-group>
          </div>
          <div class="mb-3">
            <b-form-group label="Params Input Type *" label-for="application.input_type" class="mt-4">
              <b-form-select
                v-model="application.input_type"
                :options="inputTypeOptions"
                id="application.input_type"
                aria-describedby="input_type-feedback"
                :class="{ 'is-invalid': submitted && $v.application.input_type.$error }"
                class="form-select"
              ></b-form-select>
              <validationMessages v-if="submitted && $v.application.input_type.$error" :fieldName="'Params Input Type'" :validationName="$v.application.input_type"></validationMessages>
            </b-form-group>
          </div>
        </div>
      </div>
      <input type="hidden" name="csrf_token" v-model="csrf_token">
    </form>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="createAppParamsKey" :disabled="tryingToSubmit">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>

